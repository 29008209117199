import { gql } from "@apollo/client";

export const GET_PARTNER_BY_SUBDOMAIN = gql`
  query getPartnerBySubdomain($subdomain: String!) {
    getPartnerBySubdomain(data: { subdomain: $subdomain }) {
      subdomain
      providerId
      widgetColor
      widgetLogo
      apiKey
      category
    }
  }
`;

export const GET_SERVER_TIME = gql`
  query getServerTime {
    getServerTime {
      time
      timeLagos
      timeJSdate
      timeLagosJsDate
      hourMin
      hourMinLagos
    }
  }
`;

export const GET_USER_TYPES = gql`
  query getUserTypes {
    getUserTypes {
      userType {
        _id
        name
        icon
        description
        providerCount
      }
    }
  }
`;
export const DOCTOR_PROFILE = gql`
  query doctorProfile($id: String!) {
    doctorProfile(data: { id: $id }) {
      profile {
        _id
        firstName
        lastName
        gender
        phoneNumber
        createdAt
        updatedAt
        email
        rating
        hospital
        specialization
        dob
        cadre
        picture
        providerId {
          _id
          name
        }
        status
        fee
        dociId
        accountDetails {
          accountName
          accountNumber
          bankName
        }
      }
    }
  }
`;

export const FIND_PROFILES = gql`
  query findProfiles($dociId: String!) {
    profiles(search: $dociId) {
      data {
        _id
        firstName
        lastName
        height
        weight
        bloodGroup
        genotype
        gender
        phoneNumber
        provider
        plan
        status
        consultations
        createdAt
        image
        rating
        pastIllness {
          id
        }
        providerId
        accountData {
          email
        }
      }
    }
  }
`;

export const GET_PLANS = gql`
  query getPlans($id: String!) {
    getPlans(user: $id) {
      plan {
        _id
        name
        amount
        description
        createdAt
        updatedAt
        provider
        providerData
        duration
        subscribed
      }
    }
  }
`;
